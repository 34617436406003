import React from 'react';
import './contact.scss';
import { scrollIds } from '../../constants/strings';
import allLogos from '../../logos/logos';

const { contact } = scrollIds;

const Contact = () => {
    const { linkedin } = allLogos;
    return (
        <div id={contact} className="row contact flex-lg-row justify-content-around">
            <div className="overlay-background-animation"/>
            <div className="col-lg-4 offset-lg-0 col-sm-8 offset-sm-0 col-12 offset-0 d-flex flex-column justify-content-center align-items-center py-1 pt-5 pt-lg-1 mt-5 mt-lg-0">
                <a href="https://www.linkedin.com/in/gustav-beck-noren/" target="_blank" rel="noopener noreferrer">
                    <img className="logo-img" src={linkedin} alt="Linkedin logo"/>
                </a>
            </div>
            <div className="col-lg-4 offset-lg-0 col-sm-10 offset-sm-0 col-12 offset-0 d-flex flex-column justify-content-center align-items-center py-3">
                <span>
                    Handcrafted by me <span className="px-2" role="img" aria-label="built"> 🔨 </span> 2018
                </span>
                <strong>
                    gustav@becknoren.se
                </strong>
            </div>
            <div className="col-lg-4 offset-lg-0 col-sm-8 offset-sm-0 col-10 offset-0 d-flex justify-content-center align-items-center py-3">
            <span className="mr-lg-5 text-center">
                Built with <strong>React</strong> using <strong>Gatsby.js</strong> with <strong>Popmotion Pose</strong> and <strong>SASS with Bootstrap 4</strong>
            </span>
            </div>
        </div>
    );
}

export default Contact;