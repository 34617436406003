import React from 'react'
import Observer from 'react-intersection-observer'
import posed from 'react-pose';

const LogoLine = posed.div({
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 400,
            delay: 1000
        }
    },
    hidden: {
        opacity: 0,
        x: -500
    }
});

const GbnLogo = () => (
    <div className="gbn-logo">
        <svg viewBox="0 0 250 150" width="250" height="150" xmlns="http://www.w3.org/2000/svg">
          <text x="0" y="80" textAnchor="start" className="home-logo-side">B</text>
          <text x="125" y="100" textAnchor="middle" className="home-logo-center">G</text>
          <text x="250" y="80" textAnchor="end" className="home-logo-side">N</text>
        </svg>
        <Observer threshold={0.5}>
            {({inView, ref}) => (
                <div ref={ref}>
                    <LogoLine pose={ inView ? 'visible' : 'hidden'} className="gbn-logo-line"/>
                </div>
            )}
        </Observer>
    </div>
)

export default GbnLogo;