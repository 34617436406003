import aws from './aws.svg';
import bootstrap from './bootstrap.svg';
import docker from './docker.svg';
import git from './git.svg';
import googleCloudPlatform from './google-cloud-platform.svg';
import html5 from './html-5.svg';
import javascript from './javascript.svg';
import nodejs from './nodejs.svg';
import paypal from './paypal.svg';
import react from './react.svg';
import sass from './sass.svg';
import tradera from './tradera.png';
import webpack from './webpack.svg';
import linkedin from './linkedin.svg';

const allLogos = {
    'aws': aws,
    'bootstrap': bootstrap,
    'docker': docker,
    'git': git,
    'googleCloudPlatform': googleCloudPlatform,
    'html5': html5,
    'javascript': javascript,
    'nodejs': nodejs,
    'paypal': paypal,
    'react': react,
    'sass': sass,
    'tradera': tradera,
    'webpack': webpack,
    'linkedin': linkedin
};

export default allLogos;