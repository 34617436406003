import React, { Component } from 'react';
import { scrollIds } from '../../constants/strings';
import Observer from 'react-intersection-observer'
import posed from 'react-pose';
import GbnLogo from './gbn-logo';
import Image from '../image';
import './home.scss';

const { home, me } = scrollIds;

const HomeTextBackground = posed.div({
    visible: {
        width: '100%',
        transition: {
            duration: 400,
            delay: 1000
        }
    },
    hidden: {
        width: '0%',
    }
});

const HomeText = posed.span({
    visible: {
        opacity: 1,
        transition: {
            duration: 400
        }
    },
    hidden: {
        opacity: 0
    }
});

const handleArrowClick = () => {
    document.getElementById(me).scrollIntoView({behavior: 'smooth', block: 'start', passive: true});
}

class Home extends Component {
    render() {
        return(
            <div id={home} className="home col-12 d-flex flex-column justify-content-center align-items-center ">
                <Image/>
                <GbnLogo/>
                <Observer threshold={0.5}>
                    {({inView, ref}) => (
                        <div ref={ref}>
                            <div className="home-text-wrapper mb-3">
                                <h3 className="mb-0 px-2">
                                    <HomeText pose={ inView ? 'visible' : 'hidden'}>
                                            WEB DEVELOPER, STOCKHOLM
                                    </HomeText>
                                </h3>
                                <HomeTextBackground pose={ inView ? 'visible' : 'hidden'} className="home-text-background" />
                            </div>
                            <div className="home-text-wrapper">
                                <h1 className="mb-0 px-2">
                                    <HomeText pose={ inView ? 'visible' : 'hidden'}>
                                        GUSTAV BECK-NORÉN
                                    </HomeText>
                                </h1>
                                <HomeTextBackground pose={ inView ? 'visible' : 'hidden'} className="home-text-background" />
                            </div>
                        </div>
                    )}
                </Observer>
                <div className="bounce" alt="Scroll down" onClick={()=> handleArrowClick()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/>
                        <path d="M0 0h24v24H0z" fill="none"/>
                    </svg>
                </div>
            </div>
        )
    }
}

export default Home;