
import * as Sentry from '@sentry/browser';

if (typeof window !== `undefined`) {
    window.Sentry = Sentry;
    
    window.onunhandledrejection = function(evt) {
        Sentry.captureException(evt.reason);
    };

    Sentry.init({ dsn: 'https://f657229b3a7442f4bc308293616e87fc@sentry.io/1361486' });
}
