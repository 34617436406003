import React from 'react';
import Observer from 'react-intersection-observer'
import posed from 'react-pose';
import ScrollAnchor from '../scroll-anchor';
import './cv.scss';
import allLogos from '../../logos/logos';
import { scrollIds } from '../../constants/strings';

const { cv } = scrollIds;
const { paypal, tradera } = allLogos;


const Text = posed.div({
    visible: {
        opacity: 1,
        transition: {
            opacity: { ease: 'easeOut', duration: 1000 },
            delay: 500
        }
    },
    hidden: {
        opacity: 0,
        transition: {
            opacity: { ease: 'easeOut', duration: 1000 }
        }
    }
});

const CV = () => (
    <div className="row cv flex-lg-row py-3">
        <ScrollAnchor scrollId={cv}/>
        <div className="col-10 offset-1 d-flex align-items-center py-3">
            <h1><span role="img" aria-label="CV">🚀</span> CV</h1>
        </div>
        <Observer threshold={0.6}>
            {({inView, ref}) => (
            <div ref={ref} className="col-10 col-lg-8 offset-1 d-flex flex-column py-1">
                <Text pose={ inView ? 'visible' : 'hidden'}>
                    <h2>Current employment<span className="d-inline d-lg-none">:</span></h2>
                    <h4>
                        <div className="mr-4 my-2 d-inline-block">
                            PayPal / Tradera (2018 - Present)
                        </div>
                        <a href="https://www.paypal.com/" target="_blank" rel="noopener noreferrer">
                            <img className="cv-logo pr-4" src={paypal} alt="PayPal logo"/>
                        </a>
                        <a href="https://www.tradera.com/" target="_blank" rel="noopener noreferrer">
                            <img className="cv-logo" src={tradera} alt="Tradera logo"/>
                        </a>
                    </h4>
                    <div>
                        Working for PayPal at Tradera based in Stockholm, Sweden. Currently working with front end development bringing the product into a new technology stack while improving the user experience for millions of users. <br/>
                        <br/> Tradera is one of Sweden’s largest online marketplaces with more than 2 million product listings every day, ranging from fashion and interior design to tech and collectibles. One of five Swedes have a Tradera account and we have around 4.5 million weekly visits and over 100 bids per second during peak traffic.
                    </div>
                </Text>
            </div>)}
        </Observer>
        <Observer threshold={0.6}>
            {({inView, ref}) => (
            <div ref={ref} className="col-10 offset-1 d-flex flex-column pt-3 pb-1">
                <Text pose={ inView ? 'visible' : 'hidden'}>
                    <h2>
                        Education<span className="d-inline d-lg-none">:</span>
                    </h2>
                    Master of Science (M.S.) Computer Science, graduation year 2015
                </Text>
           </div>)}
        </Observer>
        <div className="col-10 offset-1 d-flex align-items-center pb-3 pt-lg-0 pt-3 cl-effect-1">
            <a className="font-weight-bold" href="https://www.linkedin.com/in/gustav-beck-noren/" target="_blank" rel="noopener noreferrer">More details on LinkedIn</a>
        </div>
        <Observer threshold={0.6}>
            {({inView, ref}) => (
        <div ref={ref} className="col-10 offset-1 d-flex flex-column py-3 interests">
            <Text pose={ inView ? 'visible' : 'hidden'}>
                <h2>
                    Interests
                </h2>
                <span role="img" aria-label="skiing">
                    ⛷️
                </span>
                <span role="img" aria-label="gym">
                    🏋🏼‍
                </span>
                <span role="img" aria-label="travel">
                    🛫
                </span>
                <span role="img" aria-label="programming">
                    💻
                </span>
            </Text>
            </div>)}
        </Observer>
    </div>
);

export default CV;