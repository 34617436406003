import React from 'react'
import Layout from '../components/layout'
import Home from '../components/home/home';
import AboutMe from '../components/about-me/about-me';
import CV from '../components/cv/cv';
import Skills from '../components/skills/skills';
import Contact from '../components/contact/contact';
import '../components/sentry';

const IndexPage = () => (
  <Layout>
    <Home/>
    <AboutMe/>
    <svg id="curveUpColor" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
      <path d="M0 100 C 20 0 50 0 100 100 Z"/>
    </svg>
    <CV/>
    <Skills/>
    <svg id="curveDownColor" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
      <path d="M0 0 C 50 100 80 100 100 0 Z"/>
    </svg>
    <Contact/>
  </Layout>
)

export default IndexPage
