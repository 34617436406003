import React, { Component } from 'react';
import posed from 'react-pose';
import ScrollAnchor from '../scroll-anchor';
import allLogos from '../../logos/logos';
import { scrollIds } from '../../constants/strings';
import './skills.scss';
import { tech } from '../../constants/strings';
import Observer from 'react-intersection-observer'

const BigLogosWrapper = posed.div({
    visible: {
        delayChildren: 500,
        staggerChildren: 100
      },
    hidden: { delay: 0 }
});

const LogosWrapper = posed.div({
    visible: {
        delayChildren: 500,
        staggerChildren: 100
      },
    hidden: { delay: 0 }
});

const SmallLogosWrapper = posed.div({
    visible: {
        delayChildren: 500,
        staggerChildren: 100
      },
    hidden: { delay: 0 }
});
  
const Logo = posed.div({
    hoverable: true,
    init: {
      scale: 1,
      boxShadow: '0px 0px 0px rgba(0,0,0,0)'
    },
    hover: {
      scale: 1.2
    },
    visible: { y: 0, opacity: 1 },
    hidden: { y: 20, opacity: 0 }
});

const SkillList = posed.ul({
    visible: {
        opacity: 1
    },
    hidden: {
        opacity: 0
    }
});

class Skills extends Component {

    constructor(props){
        super(props);

        this.state = {
            visible: false,
            bigVisible: false,
            smallVisible: false
        };

        this.theObverver = null;
        this.theBigObserver = null;
        this.theSmallObserver = null;
    }

    componentDidMount() {
        const bigLogos = document.getElementById('big-logos-wrapper');
        const logos = document.getElementById('logos-wrapper');
        const smallLogos = document.getElementById('small-logos-wrapper');

        this.theObverver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    this.setState({
                        visible: true
                    });
                } else {
                    this.setState({
                        visible: false
                    });
                }
              });
        });

        this.theBigObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    this.setState({
                        bigVisible: true
                    });
                } else {
                    this.setState({
                        bigVisible: false
                    });
                }
            });
        });

        this.theSmallObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    this.setState({
                        smallVisible: true
                    });
                } else {
                    this.setState({
                        smallVisible: false
                    });
                }
            });
        });

        this.theObverver.observe(logos);
        this.theBigObserver.observe(bigLogos);
        this.theSmallObserver.observe(smallLogos);
    }

    componentWillUnmount() {
        this.theObverver.disconnect();
        this.theBigObserver.disconnect();
        this.theSmallObserver.disconnect();
    }

    render() {
        const { visible, bigVisible, smallVisible } = this.state;
        const { skillset } = scrollIds;
        const { aws, javascript, html5, nodejs, react, sass, googleCloudPlatform, git, bootstrap } = allLogos;
        return(
            <div className="row skills py-5">
                <ScrollAnchor scrollId={skillset}/>
                <div className="col-10 offset-1 d-flex align-items-center py-2">
                    <h1 className="d-flex">
                        <span role="img" aria-label="skills" className="lightbulb">💡</span> Skills<span className="d-block d-lg-none">:</span>
                    </h1>
                </div>
                <div className="col-xl-4 offset-xl-1 col-sm-10 offset-sm-1 col-12 d-flex flex-column">
                    <Observer threshold={0.5}>
                        {({inView, ref}) => (
                            <div ref={ref}>
                                    <SkillList pose={inView ? 'visible' : 'hidden'}>
                                        <li className="d-flex mt-3 mb-2">
                                            <h2 className="skill-text">
                                                {tech.js}
                                            </h2>
                                        </li>
                                        <li className="d-flex mt-3 mb-2">
                                            <h2 className="skill-text">
                                                {tech.html}
                                            </h2>
                                        </li>
                                        <li className="d-flex mt-3 mb-2">
                                            <h2 className="skill-text">
                                                {tech.build}
                                            </h2>
                                        </li>
                                        <li className="d-flex mt-3 mb-2">
                                            <h2 className="skill-text">
                                                {tech.hosting}
                                            </h2>
                                        </li>
                                    </SkillList>
                            </div>
                        )}
                    </Observer>
                </div>
                <div className="col-xl-6 offset-xl-0 d-flex flex-column align-items-center justify-content-center py-xl-0 py-5 skill-logos-wrapper">
                    <BigLogosWrapper id="big-logos-wrapper" className="d-flex flex-wrap align-items-center align-content-center" pose={bigVisible ? 'visible' : 'hidden'}>
                        <Logo className="skill-logo">
                            <img className="logo-img" src={javascript} alt="javascript logo"/>
                        </Logo>
                        <Logo className="skill-logo">
                            <img className="logo-img" src={html5} alt="html5 logo"/>
                        </Logo>
                        <Logo className="skill-logo">
                            <img className="logo-img" src={react} alt="react logo"/>
                        </Logo>
                        <Logo className="skill-logo">
                            <img className="logo-img" src={nodejs} alt="nodejs logo"/>
                        </Logo>
                    </BigLogosWrapper>
                    <LogosWrapper id="logos-wrapper" className="d-flex flex-wrap align-items-center align-content-center mt-5" pose={visible ? 'visible' : 'hidden'}>
                        <Logo className="skill-logo-sm">
                            <img className="logo-img" src={bootstrap} alt="bootstrap logo"/>
                        </Logo>
                        <Logo className="skill-logo-sm">
                            <img className="logo-img" src={sass} alt="sass logo"/>
                        </Logo>
                        <Logo className="skill-logo">
                            <img className="logo-img" src={git} alt="git logo"/>
                        </Logo>
                    </LogosWrapper>       
                    <SmallLogosWrapper id="small-logos-wrapper" className="d-flex flex-wrap align-items-center align-content-center mt-5" pose={smallVisible ? 'visible' : 'hidden'}>
                        <Logo className="skill-logo-xs">
                            <img className="logo-img" src={googleCloudPlatform}  alt="Google Cloud Platform logo"/>
                        </Logo>
                        <Logo className="skill-logo-xs">
                            <img className="logo-img" src={aws}  alt="AWS logo"/>
                        </Logo>
                    </SmallLogosWrapper>       
                </div>
            </div>
        )
    }
}

export default Skills;