import React from 'react';

const anchorStyle = {
    position: 'absolute',
    top: '-72px'
}

const ScrollAnchor = ({scrollId}) => (
    <div id={scrollId} style={anchorStyle} />
)

export default ScrollAnchor;