import React from 'react';
import Observer from 'react-intersection-observer'
import posed from 'react-pose';
import ScrollAnchor from '../scroll-anchor';
import './about-me.scss';
import { aboutMeText } from '../../constants/strings';
import { scrollIds } from '../../constants/strings';

const ProfileImage = posed.div({
    hoverable: true,
    pressable: true,
    init: {
      scale: 1,
      boxShadow: '0px 0px 0px rgba(0,0,0,0)'
    },
    hover: {
      scale: 1.2,
      boxShadow: '0px 5px 10px rgba(0,0,0,0.2)'
    },
    press: {
      scale: 1.1,
      boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
    },
    visible: {
        opacity: 1,
        transition: {
            opacity: { ease: 'easeOut', duration: 1000 }
        }
    },
    hidden: {
        opacity: 0,
        transition: {
            opacity: { ease: 'easeOut', duration: 1000 }
        }
    }
  });

const ProfileText = posed.span({
    visible: {
        opacity: 1,
        transition: {
            opacity: { ease: 'easeOut', duration: 1000 }
        }
    },
    hidden: {
        opacity: 0,
        transition: {
            opacity: { ease: 'easeOut', duration: 1000 }
        }
    }
});

const { me } = scrollIds;

const AboutMe = () => (
    <div className="row about-me flex-lg-row flex-column-reverse justify-content-around">
        <ScrollAnchor scrollId={me}/>
        <div className="col-lg-4 offset-lg-1 col-12 d-flex justify-content-center align-items-center pb-5">
            <Observer threshold={0.6}>
                {({inView, ref}) => (
                        <div ref={ref}>
                            <ProfileImage className="profile-image" pose={ inView ? 'visible' : 'hidden'}/>
                        </div>
                    )
                }
            </Observer>
        </div>
        <div className="col-lg-6 col-12 d-flex justify-content-center align-items-center py-5">
            <Observer threshold={0.5}>
            {({inView, ref}) => (
                <div ref={ref} className="profile-text text-center px-3">
                    <ProfileText pose={ inView ? 'visible' : 'hidden'}>
                        <h1>Hi! <span role="img" aria-label="Hi!">👋🏻</span></h1>
                        {aboutMeText}
                    </ProfileText>
                </div>
                )
            }
            </Observer>
        </div>
    </div>
);

export default AboutMe;